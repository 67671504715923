import React from "react"
import PropTypes from "prop-types"
import * as styles from "./Footer-tw-styles"
import ResponsiveImage from "../../UI/ResponsiveImage/ResponsiveImage"
import Link from "../../UI/Link/Link"
import useScreenSize from "../../../hooks/useScreenSize"

const FooterMobile = props => {
    const { style, footerImage, footerImageText, mainLinksCollection, brandLogoImage, baseLinks, copyright } = props
    return (
        <div className={style.wrapper}>
            <div className={style.footerImageWrapper}>
                {!!footerImage && <ResponsiveImage contentfulImagesComponent={footerImage} />}
                {footerImageText && <h5 className={style.footerImageText}>{footerImageText}</h5>}
            </div>
            <hr className={style.hr} />
            <div className={style.mainLinksContainer}>
                {mainLinksCollection?.items.map((item, index) => (
                    <div key={`${item.sys.id}_${index}`} className={style.mainLinkColumn}>
                        {item.title && <h5 className={style.mainLinkHeading}>{item.title}</h5>}
                        {item.linksCollection?.items.map((link, index) => (
                            <Link
                                key={`${item.sys.id}_footer_mobile_main_link_${index}`}
                                {...link}
                                className={style.mainLink}
                            />
                        ))}
                    </div>
                ))}
            </div>
            <hr className={style.hr} />
            <div className={style.bottomContainer}>
                <div className={style.row}>
                    {!!brandLogoImage && (
                        <div className={style.brandLogoWrapper}>
                            <ResponsiveImage contentfulImagesComponent={brandLogoImage} />
                        </div>
                    )}
                    {/* Language Switcher */}
                    <div className={style.languageSwitcherWrapper}>&nbsp;</div>
                </div>
                <div className={style.baseLinksWrapper}>
                    {baseLinks?.linksCollection?.items.map((item, index) => (
                        <Link
                            key={`${baseLinks.sys.id}_footer_mobile_base_link_${index}`}
                            {...item}
                            className={style.baseLink}
                        />
                    ))}
                </div>
                {copyright && <div className={style.copyright}>{copyright}</div>}
            </div>
        </div>
    )
}
const FooterDesktop = props => {
    const { style, footerImage, footerImageText, mainLinksCollection, brandLogoImage, copyright, baseLinks } = props
    return (
        <div className={style.wrapper}>
            <div className={style.firstRow}>
                <div className={style.footerImageWrapper}>
                    {!!footerImage && <ResponsiveImage contentfulImagesComponent={footerImage} />}
                    {footerImageText && <h5 className={style.footerImageText}>{footerImageText}</h5>}
                </div>
                <div className={style.mainLinksContainer}>
                    {mainLinksCollection?.items.map((item, index) => (
                        <div key={`${item.sys.id}_${index}`} className={style.mainLinkColumn}>
                            {item.title && <h5 className={style.mainLinkHeading}>{item.title}</h5>}
                            {item.linksCollection?.items.map((link, index) => (
                                <Link
                                    key={`${item.sys.id}_footer_desktop_main_link_${index}`}
                                    {...link}
                                    className={style.mainLink}
                                />
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <hr className={style.hr} />
            <div className={style.lastRow}>
                <div className={style.leftColumn}>
                    {!!brandLogoImage && (
                        <div className={style.brandLogoWrapper}>
                            <ResponsiveImage contentfulImagesComponent={brandLogoImage} />
                        </div>
                    )}
                    {copyright && <div className={style.copyright}>{copyright}</div>}
                </div>
                <div className={style.rightColumn}>
                    <div className={style.baseLinksWrapper}>
                        {baseLinks?.linksCollection?.items.map((item, index) => (
                            <Link
                                key={`${baseLinks.sys.id}_footer_desktop_base_link_${index}`}
                                {...item}
                                className={style.baseLink}
                            />
                        ))}
                    </div>
                    {/* Language Switcher */}
                    <div className={style.languageSwitcherWrapper}>&nbsp;</div>
                </div>
            </div>
        </div>
    )
}

const Footer = props => {
    const style = styles.defaultStyle
    const screenSize = useScreenSize()
    return (
        <footer className={style.footer}>
            {screenSize?.isMobileOrTablet ? (
                <FooterMobile {...props} style={style.mobile} />
            ) : (
                <FooterDesktop {...props} style={style.desktop} />
            )}
        </footer>
    )
}

FooterDesktop.propTypes = {
    style: PropTypes.object,
    ...Footer.propTypes,
}
FooterMobile.propTypes = {
    style: PropTypes.object,
    ...Footer.propTypes,
}

Footer.propTypes = {
    footerImage: PropTypes.object,
    footerImageText: PropTypes.string,
    mainLinksCollection: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape()),
    }),
    baseLinks: PropTypes.object,
    brandLogoImage: PropTypes.object,
    copyright: PropTypes.string,
}

export default Footer
