import React from "react"
import ScrollIcon from "../../assets/icons/ScrollIcon"
import PropTypes from "prop-types"
import { useGlobalContext } from "../../context/GlobalContext"
import { gaEventClasses, gaEventLabels } from "../../constants/gtm.constants"

function ScrollDownWrapper({ children }) {
    const labels = useGlobalContext().labels
    const buttonClickHandler = () => {
        const parentDiv = document.querySelector(".reference-div")
        const parentHeight = parentDiv.offsetHeight
        const currentScrollPosition = window.scrollY
        window.scrollBy({
            top: parentHeight - currentScrollPosition,
            behavior: "smooth",
        })
    }
    const buttonStyles =
        "scroll-button-clip flex flex-col items-center justify-center bg-black text-white w-[84px] h-[79px] lg:w-[96px] lg:h-[96px]"
    return (
        <div className="reference-div relative">
            {children}
            <div className="absolute bottom-0 left-1/2 -translate-x-1/2 z-[10]">
                <button
                    onClick={buttonClickHandler}
                    className={`${buttonStyles} ${gaEventClasses.image_click}`}
                    data-action-detail={gaEventLabels.scrollDown}
                >
                    <ScrollIcon />
                    <span className="body-s font-normal">{labels?.scrollDownLabel || "Scroll down"}</span>
                </button>
            </div>
        </div>
    )
}

export default ScrollDownWrapper

ScrollDownWrapper.propTypes = {
    children: PropTypes.node,
}
