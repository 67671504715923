export const defaultStyle = {
    wrapper: "relative flex flex-col gap-[24px]",
    imageWrapper: "relative w-full flex items-end justify-center",
    imageBg: "absolute top-[30%] right-0 bottom-0 left-0 range-card-clip",
    image: "relative",
    content: {
        richTextWrapper: "relative text-black flex flex-col gap-2",
        code: "hidden",
        link: "hidden",
        paragraph: "body-l font-normal [&_b]:font-black",
        heading1: "hidden",
        heading2: "hidden",
        heading3: "hidden",
        heading4: "heading-4",
        heading5: "hidden",
        heading6: "hidden",
        table: "hidden",
        tRow: "hidden",
        tHeader: "hidden",
        tData: "hidden",
        ulList: "hidden",
        list: "hidden",
        title: "hidden",
        mainLink: "hidden",
    },
}
