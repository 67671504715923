export const REQUIRED = "REQUIRED"
export const EMAIL = "EMAIL"
export const EQUAL_TO = "EQUAL_TO"
export const PASSWORD = "password"
export const ZIPCODE = "ZIPCODE"
export const CHECKBOX = "CHECKBOX"
export const LENGTH = "LENGTH"
export const TOGGLE_PASSWORD = "togglePassword"

export const SORT_TOP_RATED = "Top Rated"
export const SORT_DEFAULT = "Default"
export const SORT_NEWEST = "Newest"
export const SORT_A_TO_Z = "A-Z"
export const SORT_Z_TO_A = "Z-A"
