import React from "react"
const GtmBody = () => {
    // renamed file
    return (
        <noscript
            dangerouslySetInnerHTML={{
                __html: `<iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-NLQ5FL5"
        height="0"
        width="0"
        style="display:none;visibility:hidden"
      ></iframe>
        `,
            }}
        />
    )
}

export default GtmBody
