import React from "react"
import { screen, render, within } from "@testing-library/react"
import DynamicTable from "../DynamicTable/DynamicTable"
import { DynamicTableProps } from "../../../mocks/DynamicTable.mock"

describe("DynamicTable", () => {
    test("should render Table", () => {
        render(<DynamicTable {...DynamicTableProps} />)
    })

    test("should render Header", () => {
        render(<DynamicTable {...DynamicTableProps} />)
        screen.getByText(`Nutrional Information`)
    })

    test("Table count", () => {
        render(<DynamicTable {...DynamicTableProps} />)
        expect(screen.getAllByRole("table").length).toBe(1)
    })

    test("Header count", () => {
        render(<DynamicTable {...DynamicTableProps} />)
        const rows = screen.getByRole("table")
        const { getAllByRole } = within(rows)
        const header = getAllByRole("columnheader")
        expect(header.length).toBe(4)
    })

    test("Row count", () => {
        render(<DynamicTable {...DynamicTableProps} />)
        const rows = screen.getByRole("table")
        const { getAllByRole } = within(rows)
        const row = getAllByRole("row")
        expect(row.length).toBe(9)
    })
})
