import React from "react"
import PropTypes from "prop-types"
import ResponsiveImage from "../UI/ResponsiveImage/ResponsiveImage"
import RichTextModel from "../RichTextModel/RichTextModel"
import * as styles from "./ImageHeroBanner-tw-styles"
import Link from "../UI/Link/Link"
import Button from "../UI/Button/Button"
import ScrollDownWrapper from "./ScrollDownWrapper"

const ImageHeroBanner = props => {
    const {
        image,
        backgroundImage,
        description,
        cta,
        sectionId,
        variant,
        showScrollPrompt = false,
        showImageOverlay = false,
    } = props
    const style = styles[variant] || styles.defaultStyle
    const { variant: buttonVariant, ...linkProps } = cta || {}
    const ImageHeroBannerContent = () => {
        return (
            <div className={style.wrapper} id={sectionId}>
                <div className={style.bgWrapper}>
                    {!!backgroundImage && (
                        <>
                            {showImageOverlay && <div className={style.bgOverlay}>&nbsp;</div>}
                            <ResponsiveImage contentfulImagesComponent={backgroundImage} className={style.bgImage} />
                        </>
                    )}
                </div>
                <div className={style.container}>
                    {!!image && <ResponsiveImage contentfulImagesComponent={image} className={style.image} />}
                    <RichTextModel richText={description} style={style.description} />
                    {!!cta && (
                        <Button variant={buttonVariant}>
                            <Link {...linkProps} className={style.cta} />
                        </Button>
                    )}
                </div>
            </div>
        )
    }
    return showScrollPrompt ? (
        <ScrollDownWrapper>
            <ImageHeroBannerContent />
        </ScrollDownWrapper>
    ) : (
        <ImageHeroBannerContent />
    )
}

ImageHeroBanner.propTypes = {
    image: PropTypes.object,
    backgroundImage: PropTypes.object,
    description: PropTypes.object,
    cta: PropTypes.object,
    sectionId: PropTypes.string,
    variant: PropTypes.string,
    showScrollPrompt: PropTypes.bool,
    showImageOverlay: PropTypes.bool,
}

export default ImageHeroBanner
