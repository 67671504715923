const { fragment: ResponsiveImageFragment } = require("../ResponsiveImage/ResponsiveImage.fragment")
const { fragment: LinkFragment } = require("../Link/Link.fragment")
const { fragment: RichTextFragment } = require("../../RichTextModel/RichText.fragment")
const fragment = `
    sys {
        id
    }
    image {
        ${ResponsiveImageFragment}
    }
    link {
        ${LinkFragment}
    }
    content {
        ${RichTextFragment}
    }
    mobileLayout
    desktopLayout
`
module.exports = { fragment }
