import React from "react"
import useScreenSize from "../../hooks/useScreenSize"

function ScrollIcon() {
    const screenSize = useScreenSize()
    return screenSize?.isMobileOrTablet ? (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="animate-scroll"
        >
            <path
                d="M5.5 7C5.5 3.68629 8.18629 1 11.5 1M19.2369 14.6308L18.5 22H9.5L8.79937 19.1975C8.60348 18.4139 8.19831 17.6983 7.6272 17.1272L6.52476 16.0248C5.9289 15.4289 5.83646 14.4953 6.30388 13.7942C6.92268 12.866 8.23595 12.7359 9.02476 13.5248L9.5 14V7.04233C9.5 5.94179 10.3892 5.04804 11.4897 5.04236C12.5983 5.03664 13.5 5.93373 13.5 7.04233V10.6226L16.9231 11.4084C18.3958 11.7465 19.3873 13.1273 19.2369 14.6308Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    ) : (
        <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 14V24" stroke="white" strokeWidth="2" className="animate-scroll" />
            <path
                d="M24 42C17.3726 42 12 36.6274 12 30V18C12 11.3726 17.3726 6 24 6C30.6274 6 36 11.3726 36 18V30C36 36.6274 30.6274 42 24 42Z"
                fill="none"
                stroke="white"
                strokeWidth="2"
            />
        </svg>
    )
}

export default ScrollIcon
