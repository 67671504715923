import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import * as styles from "./Header-tw-styles"
import Link from "../../UI/Link/Link"
import { gaEventClasses, gaEventLabels } from "../../../constants/gtm.constants"
import useScrollBlock from "../../../hooks/useScrollBlock"

const Header = props => {
    const style = styles.defaultStyle
    const { logo, menuElementsCollection } = props
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [blockScroll, allowScroll] = useScrollBlock()
    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen)
    }

    useEffect(() => {
        if (isMenuOpen) {
            blockScroll()
        } else {
            allowScroll()
        }
        return () => {
            allowScroll()
        }
    }, [isMenuOpen])

    return (
        <header className={style.header}>
            <div className={style.sectionWrapper}>
                <div className={style.wrapper}>
                    <div
                        className={style.logoWrapper}
                        onClick={() => {
                            setIsMenuOpen(false)
                        }}
                    >
                        <Link url="/" gaEventClass={gaEventClasses.logo} gaEventLabel={gaEventLabels.logo}>
                            <img
                                src={logo.url}
                                width={logo.width}
                                height={logo.height}
                                alt={logo.title}
                                loading="eager"
                            />
                        </Link>
                    </div>
                    <div className={style.desktopLinksWrapper}>
                        {menuElementsCollection.items.map((menuElement, index) => {
                            return (
                                <Link
                                    key={`menu_elem_desktop_${index}`}
                                    {...menuElement}
                                    className={style.desktopLink}
                                />
                            )
                        })}
                        {/* TODO: Add Language Switcher on phase 2  */}
                    </div>
                    <button className={style.burgerButton} onClick={handleMenuToggle}>
                        <span className={`${style.burger1Line} ${isMenuOpen ? style.burgerLine1Active : ""}`}>
                            &nbsp;
                        </span>
                        <span className={`${style.burger2Line} ${isMenuOpen ? style.burgerLine2Active : ""}`}>
                            &nbsp;
                        </span>
                        <span className={`${style.burger3Line} ${isMenuOpen ? style.burgerLine3Active : ""}`}>
                            &nbsp;
                        </span>
                    </button>
                </div>
                <div
                    className={`${style.mobileMenuWrapper} ${isMenuOpen ? "block" : "hidden"}`}
                    onClick={() => {
                        setIsMenuOpen(false)
                    }}
                >
                    <div className={style.mobileMenu}>
                        <hr className={style.mobileMenuHr} />
                        <div className={style.mobileMenuLinksWrapper}>
                            {menuElementsCollection.items.map((menuElement, index) => {
                                return (
                                    <React.Fragment key={`menu_elem_mobile_${index}`}>
                                        <Link {...menuElement} className={style.mobileMenuLink} />
                                        <hr className={style.mobileMenuHr} />
                                    </React.Fragment>
                                )
                            })}
                        </div>
                        <hr className={style.mobileMenuHr} />
                    </div>
                </div>
            </div>
        </header>
    )
}

Header.propTypes = {
    logo: PropTypes.object,
    menuElementsCollection: PropTypes.object,
}

export default Header
