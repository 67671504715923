export const DynamicTableProps = {
    rowHeading: ["Typical values", "Nutrition Per 100g", "GDA", "%"],
    rowData: [
        {
            id: 0,
            value: "Energy",
            serving: "964 kJ / 235 kcal",
            gda: "2000",
            percent: "11.8%",
        },
        {
            id: 1,
            value: "Protein",
            serving: "5.5g",
            gda: "50g",
            percent: "11.0%",
        },
        {
            id: 2,
            value: "Carbohydrate",
            serving: "4.0g",
            gda: "260g",
            percent: "1.5%",
        },
        {
            id: 3,
            value: "of which Sugars",
            serving: "4.0g",
            gda: "90g",
            percent: "4.4%",
        },
        {
            id: 4,
            value: "Fat",
            serving: "21.5g",
            gda: "70g",
            percent: "30.7%",
        },
        {
            id: 5,
            value: "of which Saturates",
            serving: "14.5",
            gda: "20g",
            percent: "72.5%",
        },
        {
            id: 6,
            value: "Fibre",
            serving: "0.2g",
            gda: "24g",
            percent: "0.8%",
        },
        {
            id: 7,
            value: "Sodium*",
            serving: "0.75g",
            gda: "6g",
            percent: "12.5%",
        },
    ],
    heading: "Nutrional Information",
    showHeading: true,
}
