export const defaultStyle = {
    wrapper: "relative",
    container: "relative",
    description: {
        richTextWrapper: "hidden",
        code: "",
        link: "",
        paragraph: "",
        heading1: "",
        heading2: "",
        heading3: "",
        heading4: "",
        heading5: "",
        heading6: "",
        table: "hidden",
        tRow: "hidden",
        tHeader: "hidden",
        tData: "hidden",
        ulList: "",
        list: "",
        title: "hidden",
        mainLink: "hidden",
    },
    bgWrapper: "absolute inset-0",
    bgOverlay: "hidden",
    bgImage: "object-cover w-full h-full",
    image: "hidden",
    cta: "hidden",
}
export const HOMEPAGE_HERO = {
    wrapper:
        "relative min-h-[510px] sm:min-h-[428px] md:min-h-[540px] lg:min-h-[600px] xxl:min-h-[800px] section-clip-bottom",
    container: "relative section-wrapper max-w-[950px] flex flex-col items-center gap-[8px]",
    description: {
        richTextWrapper:
            "text-white text-center flex flex-col gap-[4px] justify-start pt-[80px] sm:pt-[120px] md:pt-[80px]",
        code: "",
        link: "",
        paragraph: "",
        heading1: "heading-1 uppercase title-shadow",
        heading2: "",
        heading3: "",
        heading4: "",
        heading5: "",
        heading6: "",
        table: "hidden",
        tRow: "hidden",
        tHeader: "hidden",
        tData: "hidden",
        ulList: "",
        list: "",
        title: "hidden",
        mainLink: "hidden",
    },
    bgWrapper: "absolute inset-0",
    bgOverlay: "absolute inset-0 bg-black opacity-[0.32]",
    bgImage: "object-cover w-full h-full",
    image: "hidden",
    cta: "",
}
export const TERMS_OF_USE = {
    wrapper: "relative min-h-[280px] md:min-h-[360px] section-clip-bottom",
    container: "relative section-wrapper max-w-[862px] flex flex-col items-center gap-[8px]",
    description: {
        richTextWrapper: "text-white text-center flex flex-col gap-[4px] justify-start pt-[120px] md:pt-[80px]",
        code: "",
        link: "",
        paragraph: "",
        heading1: "",
        heading2: "heading-2 uppercase title-shadow",
        heading3: "",
        heading4: "",
        heading5: "",
        heading6: "",
        table: "hidden",
        tRow: "hidden",
        tHeader: "hidden",
        tData: "hidden",
        ulList: "",
        list: "",
        title: "hidden",
        mainLink: "hidden",
    },
    bgWrapper: "absolute inset-0",
    bgOverlay: "absolute inset-0 bg-black opacity-[0.32]",
    bgImage: "object-cover w-full h-full",
    image: "hidden",
    cta: "",
}
export const NOT_FOUND_BANNER = {
    wrapper: "relative min-h-[280px] md:min-h-[480px] section-clip-bottom",
    container: "relative section-wrapper max-w-[862px] flex flex-col items-center gap-[8px]",
    description: {
        richTextWrapper: "text-white text-center flex flex-col gap-[4px] justify-start pt-[120px] md:pt-[185px]",
        code: "",
        link: "",
        paragraph: "",
        heading1: "",
        heading2: "heading-2 uppercase title-shadow",
        heading3: "",
        heading4: "",
        heading5: "",
        heading6: "",
        table: "hidden",
        tRow: "hidden",
        tHeader: "hidden",
        tData: "hidden",
        ulList: "",
        list: "",
        title: "hidden",
        mainLink: "hidden",
    },
    bgWrapper: "absolute inset-0",
    bgOverlay: "absolute inset-0 bg-black opacity-[0.32]",
    bgImage: "object-cover w-full h-full",
    image: "hidden",
    cta: "",
}
export const NOT_FOUND_CONTENT = {
    wrapper: "relative section-clip-top pb-12",
    container: "relative section-wrapper max-w-[862px] flex flex-col items-center gap-[32px]",
    description: {
        richTextWrapper: "",
        code: "",
        link: "",
        paragraph: "",
        heading1: "",
        heading2: "",
        heading3: "heading-3 uppercase text-center",
        heading4: "",
        heading5: "",
        heading6: "",
        table: "hidden",
        tRow: "hidden",
        tHeader: "hidden",
        tData: "hidden",
        ulList: "",
        list: "",
        title: "hidden",
        mainLink: "hidden",
    },
    bgWrapper: "absolute inset-0",
    bgOverlay: "absolute inset-0 bg-black opacity-[0.32]",
    bgImage: "object-cover w-full h-full",
    image: "md:max-w-[633px]",
    cta: "",
}

export const HOMEPAGE_BANNER = {
    wrapper:
        "relative flex min-h-[510px] sm:min-h-[428px] md:min-h-[640px] lg:min-h-[680px] xxl:min-h-[720px] section-clip-top",
    container: "relative section-wrapper max-w-[950px] flex flex-col items-center gap-[8px] pb-[40px] md:pb-[80px]",
    description: {
        richTextWrapper:
            "flex-1 text-white text-center flex flex-col gap-[4px] justify-start pt-[60px] md:pt-[90px] lg:pt-[20px]",
        code: "",
        link: "",
        paragraph: "",
        heading1: "",
        heading2: "heading-2 uppercase title-shadow",
        heading3: "",
        heading4: "",
        heading5: "",
        heading6: "",
        table: "hidden",
        tRow: "hidden",
        tHeader: "hidden",
        tData: "hidden",
        ulList: "",
        list: "",
        title: "hidden",
        mainLink: "hidden",
    },
    bgWrapper: "absolute inset-0",
    bgOverlay: "absolute inset-0 bg-black opacity-[0.32]",
    bgImage: "object-cover w-full h-full",
    image: "hidden",
    cta: "",
}
