export const dynamiTableStyles = {
    tableContainer: "tableContainer lg:w-[540px] md:w-[468px] mx-auto px-8 md:px-0 flex font-normal flex-wrap",
    tableTitle: "font-normal text-3xl leading-[37.5px] mx-0 mb-30 mt-20 p-0 text-center font-ProxNarrow tracking-[1px]",
    tableWrpr: "pt-20 recipeTable ",
}

export const recipe_nutrition = {
    tableContainer: "NutritionTable",
    tableTitle: "font-normal text-3xl leading-4 mx-0 mb-30 mt-px p-0 text-center",
}
