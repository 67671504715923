const fragment = `
    sys {
        id
    }
    url
    label
    icon {
        url
        width
        height
    }
    gaEventClass
    gaEventLabel
`
module.exports = { fragment }
