import React from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"

function YoutubeModal(props) {
    const { isOpen, onClose, modalRef, videoId } = props
    if (!isOpen) return null
    const iframeSrc = `https://www.youtube.com/embed/${videoId}?autoplay=1`
    const modal = (
        <div
            role="dialog"
            aria-modal="true"
            className="fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-50 z-[50] px-[4px] md:py-[24px] md:px-[24px] lg:py-[32px] lg:px-[102px] overflow-auto"
            onClick={onClose}
        >
            <div
                ref={modalRef}
                tabIndex="-1"
                onClick={e => e.stopPropagation()}
                className="mt-[80px] md:mt-[30px] lg:mt-0 aspect-video max-w-[1440px] mx-auto"
            >
                {iframeSrc && (
                    <iframe
                        width="1920"
                        height="1080"
                        className="w-full h-full"
                        src={iframeSrc}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                )}
            </div>
        </div>
    )
    return ReactDOM.createPortal(modal, document.getElementById("modal-root"))
}

export default YoutubeModal

YoutubeModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    modalRef: PropTypes.object,
    videoId: PropTypes.string,
}
