export const defaultStyle = {
  wrapper:
    "hidden",
  container: "",
  content: "",
  text: "",
  contentImage: "",
  image: "",
}
export const HOMEPAGE = {
  wrapper:
    "relative section-clip-top section-clip-bottom",
  container: "px-[20px] my-[60px] md:my-[40px] md:px-[40px] lg:my-[80px] lg:px-0 lg:max-w-[860px] lg:mx-auto lg:flex lg:gap-[24px]",
  content: "flex flex-col justify-center items-center gap-[32px] md:max-w-[702px] md:mx-auto lg:items-start",
  text: "body-l ",
  contentImage: "max-w-[240px] md:max-w-[320px] lg:max-w-[350px]",
  image: "mt-[24px] max-w-[280px] mx-auto md:max-w-[346px] lg:max-w-[418px]",
}
