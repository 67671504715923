import { Fragment, createElement, memo } from "react"
import PropTypes from "prop-types"
import { marked } from "marked"
import parse from "html-react-parser"
import { Link } from "gatsby"

const allowed_br = ["p"]

const handleTagImage = (childDomNode, index) => {
    if (childDomNode.type === "tag" && childDomNode.name === "img") {
        return createElement("img", {
            key: index,
            src: childDomNode.attribs.src,
            alt: childDomNode.attribs.alt || "image",
        })
    } else if (childDomNode.data) {
        return childDomNode.data
    } else {
        return createElement(Fragment, { key: index })
    }
}

const Typography = ({ className, component: Component, content, br_allowed, allow_br_tag, ...other }) =>
    createElement(
        Component,
        { ...(className ? { className } : {}), ...other },
        parse(marked(content || ""), {
            replace: domNode => {
                if (domNode.type === "tag" && domNode.name === "br") {
                    return allow_br_tag ? createElement("br") : createElement(Fragment)
                } else if (domNode.type === "tag" && domNode.name === "a") {
                    return createElement(
                        Link,
                        { ...domNode.attribs },
                        domNode.children.map((childDomNode, index) => {
                            return handleTagImage(childDomNode, index)
                        })
                    )
                } else if (
                    ((domNode.parent && allowed_br.indexOf(domNode.parent.name) !== -1) ||
                        (!domNode.parent && domNode.prev && domNode.next)) &&
                    domNode.type === "text" &&
                    domNode.data === "\n" &&
                    br_allowed
                ) {
                    return createElement("br")
                } else if (domNode.type === "tag" && domNode.name === "img") {
                    return createElement("img", {
                        src: domNode.attribs.src,
                        alt: domNode.attribs.alt || "image",
                    })
                }
            },
            trim: true,
        })
    )

Typography.propTypes = {
    className: PropTypes.string,
    component: PropTypes.string,
    content: PropTypes.string,
    br_allowed: PropTypes.bool,
    allow_br_tag: PropTypes.bool,
}

Typography.defaultProps = {
    className: "",
    component: "div",
    content: "",
    br_allowed: true,
    allow_br_tag: false,
}

export default memo(Typography)
