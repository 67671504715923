import React from "react"
import { screen, render } from "@testing-library/react"
import Button from "../Button/Button"
import { buttonProps } from "../../../mocks/button.mock"

describe("Button", () => {
    render(<Button {...buttonProps} />)
    test("should render component", () => {
        expect(screen.getByText("Submit"))
    })
})
