/* 
  Hook to query data using Gatsby's useStaticQuery React hook
*/

import { graphql, useStaticQuery } from "gatsby"
const useSiteMetadata = () => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        siteUrl
                    }
                }
            }
        `
    )

    return site.siteMetadata
}

export default useSiteMetadata
