export const imageConstants = {
    mobileNavToggle: {
        url: "https://images.ctfassets.net/qc3lz96t0r4x/2GVW0xVj8nslVrxyr3BhMJ/0e2b5b464046b58f424c776ecf2e68ca/mobile-nav-toggle.svg",
        title: "Mobile Nav toggle",
        description: "Mobile Nav toggle",
        height: 52,
        width: 50,
    },
    close: {
        url: "https://images.ctfassets.net/qc3lz96t0r4x/43lX9DsnYw5JPOhi1EyLrD/acc1f786724ddacf2785fb6d8f278870/close-icon.png",
        title: "Close",
        description: "Close",
        height: 50,
        width: 40,
    },
    arrowRight: {
        url: "https://images.ctfassets.net/qc3lz96t0r4x/1iF5RBHzCH7p9Ss2GxRmMO/46fe9f44a118db06fe9c184d5c28ea43/arrow-right.svg",
        title: "arrow right",
        description: "arrow right",
    },
    arrowDown: {
        url: "https://images.ctfassets.net/qc3lz96t0r4x/7qSI4tE8IbOspSsOlTAWbT/5f59076e5eb00261a52474652b4a0552/arrow.png",
        title: "arrow Down",
        description: "arrow down",
        height: 20,
        width: 12,
    },
    playBtn: {
        url: "https://images.ctfassets.net/qc3lz96t0r4x/qbPCAtOMwEkzn5zDehGAQ/33db1fdad58f8cec0ef4a33d24169b29/play-btn2.svg",
        title: "Play Button",
        description: "Play Button",
        height: 72,
        width: 72,
    },
    recipeFilterType: {
        url: "https://images.ctfassets.net/qc3lz96t0r4x/7FI2Uwzjcp0rhWoubYPsig/41b649cef7370bd45894844462ff4677/98220.png",
        title: "Type of Philadelphia",
        description: "Type of Philadelphia",
        height: 200,
        width: 200,
    },
}
