const { fragment: BannerCardFragment } = require("../UI/BannerCard/BannerCard.fragment")
const { fragment: RangeCardFragment } = require("../UI/RangeCard/RangeCard.fragment")
const { fragment: ImageCardFragment } = require("../UI/ImageCard/ImageCard.fragment")
const { fragment: VideoCardFragment } = require("../UI/VideoCard/VideoCard.fragment")
const { fragment: ResponsiveImageFragment } = require("../UI/ResponsiveImage/ResponsiveImage.fragment")

const query = (id, isPreview) => `
{
    gridSection(id: "${id}", preview: ${isPreview}) {
        internalTitle
        title
        itemsCollection(limit:10) {
            items {
                internalTitle
                content {
                    __typename
                    ... on BannerCard {
                        ${BannerCardFragment}
                    }
                    ... on RangeCard {
                        ${RangeCardFragment}
                    }
                    ... on ImageCard {
                        ${ImageCardFragment}
                    }
                    ... on VideoCard {
                        ${VideoCardFragment}
                    }
                }
                gridClasses
            }
        }
        variant
        sectionId
        decoration1 {
            ${ResponsiveImageFragment}
        }
        decoration2 {
            ${ResponsiveImageFragment}
        }
    }
}
`
module.exports = { query }
