const { fragment: LinkFragment } = require("../../UI/Link/Link.fragment")
const query = (isPreview, locale) => `{
  headerCollection(preview: ${isPreview}, limit: 1, locale: "${locale}") {
    items {
      internalTitle
      logo {
        url
        title
        description
        width
        height
      }
      menuElementsCollection {
        items {
          __typename
          ... on Link {
            ${LinkFragment}
          }
        }
      }
    }
  }
}
`

module.exports = { query }
