import { useState, useCallback, useEffect, useRef } from "react"
import useScrollBlock from "./useScrollBlock"

const useModal = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [blockScroll, allowScroll] = useScrollBlock()
    const modalRef = useRef(null)
    const lastFocusedElement = useRef(null)

    const openModal = useCallback(() => {
        lastFocusedElement.current = document.activeElement
        setIsOpen(true)
    }, [])

    const closeModal = useCallback(() => {
        setIsOpen(false)
        if (lastFocusedElement.current) {
            lastFocusedElement.current.focus()
        }
    }, [])

    const handleKeyDown = useCallback(
        event => {
            if (event.key === "Escape") {
                closeModal()
            }
            if (event.key === "Tab" && modalRef.current) {
                const focusableElements = modalRef.current.querySelectorAll(
                    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
                )
                const firstElement = focusableElements[0]
                const lastElement = focusableElements[focusableElements.length - 1]

                if (event.shiftKey && document.activeElement === firstElement) {
                    lastElement.focus()
                    event.preventDefault()
                } else if (!event.shiftKey && document.activeElement === lastElement) {
                    firstElement.focus()
                    event.preventDefault()
                }
            }
        },
        [closeModal]
    )

    useEffect(() => {
        if (isOpen && modalRef.current) {
            modalRef.current.focus()
            document.addEventListener("keydown", handleKeyDown)
            blockScroll()
        } else {
            document.removeEventListener("keydown", handleKeyDown)
            allowScroll()
        }

        return () => {
            document.removeEventListener("keydown", handleKeyDown)
            allowScroll()
        }
    }, [isOpen, handleKeyDown])

    const toggleModal = useCallback(() => {
        setIsOpen(prev => !prev)
    }, [])

    return {
        isOpen,
        openModal,
        closeModal,
        toggleModal,
        modalRef,
    }
}

export default useModal
