import React from "react"
import PropTypes from "prop-types"
import * as styles from "./dynamiTable-tw-styles"
import Typography from "../Typography/Typography"

const DynamicTable = props => {
    const { rowHeading, rowData, heading, variant, showHeading } = props
    const style = styles[variant] || styles.dynamiTableStyles
    // column keys
    const column = rowData && rowData?.length && Object.keys(rowData[0]).filter(item => item !== "id")

    // heading data
    const thData = () => {
        return rowHeading?.map(heading => (
            <th className={style?.thStyle} key={heading}>
                {heading}
            </th>
        ))
    }

    // column data
    const tdData = () => {
        return rowData?.map((data, index) => (
            <tr key={index}>
                {column?.map((key, idx) => {
                    return <td key={idx}>{data[key]}</td>
                })}
            </tr>
        ))
    }

    return (
        <div className={style?.tableWrpr}>
            {heading && showHeading && <Typography className={style?.tableTitle} content={heading} />}
            <table className={style?.tableContainer}>
                <thead>
                    <tr className={style?.thDataWrpr}>{thData()}</tr>
                </thead>
                <tbody>{tdData()}</tbody>
            </table>
        </div>
    )
}

DynamicTable.propTypes = {
    rowHeading: PropTypes.array,
    rowData: PropTypes.array,
    heading: PropTypes.string,
    variant: PropTypes.string,
    showHeading: PropTypes.bool,
}

export default DynamicTable
