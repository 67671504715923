export const defaultStyle = {
    wrapper: "",
    richTextWrapper: "section-wrapper pb-4",
    decoration1Wrapper: "hidden",
    decoration1Image: "hidden",
    decoration2Wrapper: "hidden",
    decoration2Image: "hidden",
    code: "body-m font-normal [&_b]:font-black",
    link: "body-m font-normal [&_b]:font-black",
    paragraph: "body-m font-normal [&_b]:font-black [&_a]:underline",
    heading1: "heading-1",
    heading2: "heading-2",
    heading3: "heading-3",
    heading4: "heading-4",
    heading5: "heading-5",
    heading6: "heading-6",
    table: "hidden",
    tRow: "hidden",
    tHeader: "hidden",
    tData: "hidden",
    ulList: "",
    list: "",
    title: "heading-1",
    mainLink: "",
}

export const TERMS_OF_USE = {
    wrapper: "relative",
    decoration1Wrapper:
        "z-[10] pointer-events-none absolute top-[30px] left-[-85px] md:top-[50px] md:left-[-90px] lg:top-[120px] lg:left-[0]",
    decoration1Image: "w-[145px] md:w-[170px] xxl:w-[265px]",
    decoration2Wrapper:
        "z-[10] pointer-events-none absolute top-[120px] right-[-110px] md:top-[180px] md:right-[-170px] lg:right-[-40px] lg:top-[250px] xxl:right-0",
    decoration2Image: "w-[157px] md:w-[257px] lg:w-[374px]",
    richTextWrapper: "section-wrapper section-clip-top max-w-[860px] last:pb-[60px] last:md:pb-[180px]",
    code: "hidden",
    link: "hover:underline",
    paragraph: "body-l font-normal [&_b]:font-black",
    heading1: "heading-1",
    heading2: "heading-2",
    heading3: "heading-3",
    heading4: "heading-4",
    heading5: "heading-5",
    heading6: "heading-6",
    table: "hidden",
    tRow: "hidden",
    tHeader: "hidden",
    tData: "hidden",
    ulList: "body-l font-normal",
    list: "body-l font-normal",
    title: "hidden",
    mainLink: "hidden",
}

export const COOKIES_FIRST = {
    wrapper: "relative",
    decoration1Wrapper:
        "z-[10] pointer-events-none absolute top-[30px] left-[-85px] md:top-[50px] md:left-[-90px] lg:top-[120px] lg:left-[0]",
    decoration1Image: "w-[145px] md:w-[170px] xxl:w-[265px]",
    decoration2Wrapper:
        "z-[10] pointer-events-none absolute top-[120px] right-[-110px] md:top-[180px] md:right-[-170px] lg:right-[-40px] lg:top-[250px] xxl:right-0",
    decoration2Image: "w-[157px] md:w-[257px] lg:w-[374px]",
    richTextWrapper: "section-wrapper section-clip-top max-w-[860px]",
    code: "hidden",
    link: "hover:underline",
    paragraph: "body-l font-normal [&_b]:font-black",
    heading1: "heading-1",
    heading2: "heading-2",
    heading3: "heading-3",
    heading4: "heading-4",
    heading5: "heading-5",
    heading6: "heading-6",
    table: "hidden",
    tRow: "hidden",
    tHeader: "hidden",
    tData: "hidden",
    ulList: "body-l font-normal",
    list: "body-l font-normal",
    title: "hidden",
    mainLink: "hidden",
}
export const COOKIES = {
    wrapper: "relative",
    decoration1Wrapper:
        "z-[10] pointer-events-none absolute top-[60%] left-[-110px] md:top-[45%] md:left-[-150px] lg:left-[0] lg:top-[50%]",
    decoration1Image: "w-[180px] md:w-[263px]",
    decoration2Wrapper:
        "z-[10] pointer-events-none absolute top-[55%] right-[-70px] md:top-[35%] md:right-[-120px] lg:right-[0]",
    decoration2Image: "w-[124px] md:w-[183px] lg:w-[211px]",
    richTextWrapper: "section-wrapper max-w-[860px]",
    code: "hidden",
    link: "hover:underline",
    paragraph: "body-l font-normal [&_b]:font-black",
    heading1: "heading-1",
    heading2: "heading-2",
    heading3: "heading-3",
    heading4: "heading-4",
    heading5: "heading-5",
    heading6: "heading-6",
    table: "hidden",
    tRow: "hidden",
    tHeader: "hidden",
    tData: "hidden",
    ulList: "body-l font-normal",
    list: "body-l font-normal",
    title: "hidden",
    mainLink: "hidden",
}
