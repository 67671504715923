const { fragment: ImageHeroBannerFragment } = require("../ImageHeroBanner/ImageHeroBanner.fragment")

const query = (id, isPreview) => `
{
    imageHeroBanner(id: "${id}", preview: ${isPreview}) {
        ${ImageHeroBannerFragment}
    }
}
`
module.exports = { query }
