export const defaultStyle = {
    wrapper: "relative overflow-hidden bannerCardGradient rounded-xl group h-[220px] md:h-[400px] xxl:h-[503px]",
    imgWrapper: "relative overflow-hidden flex-1",
    img: "absolute inset-0 object-cover object-center w-full h-full transition-transform duration-300 ease-in-out group-hover:scale-105",
    text: "flex flex-1 items-center justify-center pl-4 pr-4",
}

export const richTextProps = {
    paragraph: "body-2 text-white",
    heading3: "heading-3 text-white mb-4 lg:mb-8",
}
