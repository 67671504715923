const { fragment: ResponsiveImageFragment } = require("../ResponsiveImage/ResponsiveImage.fragment")
const { fragment: RichTextFragment } = require("../../RichTextModel/RichText.fragment")
const { fragment: LinkFragment } = require("../../UI/Link/Link.fragment")
const fragment = `
    sys {
        id
    }
    image {
        ${ResponsiveImageFragment}
    }
    content {
        ${RichTextFragment}
    }
    link {
        ${LinkFragment}
    }
    variant
`
module.exports = { fragment }
