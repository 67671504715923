export const checkPx = value => {
    if (typeof value === "string") {
        return value.includes("px") ? value : `${value}px`
    }
    return null
}

export const convertToWebpServer = origUrl => {
    const imageQualitySize = 80
    const hasAdditionalParams = !/.(png|gif|jpg|jpeg|svg)$/.test(origUrl)

    const concatenationSymbol = hasAdditionalParams ? "&" : "?"

    const isSvg = /.svg/.test(origUrl)

    return isSvg
        ? `${origUrl}${concatenationSymbol}q=${imageQualitySize}`
        : `${origUrl}${concatenationSymbol}fm=webp&q=${imageQualitySize}`
}

export const getImageType = url => (/.svg/.test(url) ? "image/svg+xml" : "image/webp")
