import React from "react"
import PropTypes from "prop-types"
import * as styles from "./SocialLink-tw-styles"

const SocialLink = props => {
    const { url, label, icon, gaEventClass, gaEventLabel } = props
    const style = styles.defaultStyle
    return (
        <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className={`${style.wrapper} ${gaEventClass ?? ""}`}
            data-action-detail={gaEventLabel || url || ""}
        >
            <span className={style.label}>{label}</span>
            {!!icon && (
                <img
                    src={icon.url}
                    width={icon.width}
                    height={icon.height}
                    alt={`${label ?? ""} icon`}
                    className={style.icon}
                />
            )}
        </a>
    )
}

SocialLink.propTypes = {
    url: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.shape({
        url: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
    }),
    gaEventClass: PropTypes.string,
    gaEventLabel: PropTypes.string,
}

export default SocialLink
