const fragment = `
    url
    label
    title
    scrollToElementId
    gaEventClass
    gaEventLabel
    variant
`
module.exports = { fragment }
