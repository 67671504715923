import React from "react"
import PropTypes from "prop-types"
import * as styles from "./RangeCard-tw-styles"
import ResponsiveImage from "../ResponsiveImage/ResponsiveImage"
import RichTextModel from "../../RichTextModel/RichTextModel"

function RangeCard(props) {
    const style = styles.defaultStyle
    const { image, content, gridClasses, color: _color } = props
    const color = _color.toLowerCase()
    return (
        <div className={`${style.wrapper} ${gridClasses}`}>
            {!!image && (
                <div className={style.imageWrapper}>
                    <div className={`${style.imageBg} bg-product-${color}`}>&nbsp;</div>
                    <ResponsiveImage contentfulImagesComponent={image} className={style.image} />
                </div>
            )}
            {!!content && <RichTextModel richText={content} style={style.content} />}
        </div>
    )
}

export default RangeCard

RangeCard.propTypes = {
    image: PropTypes.object,
    content: PropTypes.object,
    gridClasses: PropTypes.string,
    color: PropTypes.oneOf(["Mint", "Beige", "Grey", "Pink"]),
}
