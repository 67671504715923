import React from "react"
import PropTypes from "prop-types"

const Radio = props => {
    const { value, name, label, onChange, checked } = props
    return (
        <div>
            {label && <label>{label}</label>}
            <input value={value} type={"radio"} name={name} onChange={onChange} checked={checked} />
        </div>
    )
}

Radio.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
}

export default Radio
