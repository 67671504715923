export const defaultStyle = {
    wrapper: "relative flex flex-col items-center lg:flex-row gap-[24px] mt-[36px] md:mt-[100px]",
    thumbnailImageWrapper: "relative w-full flex-1 flex items-center justify-center lg:justify-end max-w-[418px]",
    thumbnailOverlay:
        "absolute inset-0 flex items-center justify-center cursor-pointer bg-black bg-opacity-20 hover:bg-opacity-0 transition-all duration-300 ease-in-out",
    content: {
        richTextWrapper: "relative w-full flex-1 flex flex-col gap-[8px] max-w-[688px] lg:max-w-[420px]",
        code: "hidden",
        link: "hidden",
        paragraph: "body-m font-normal [&_b]:font-black",
        heading1: "hidden",
        heading2: "hidden",
        heading3: "heading-3 uppercase",
        heading4: "hidden",
        heading5: "hidden",
        heading6: "hidden",
        table: "hidden",
        tRow: "hidden",
        tHeader: "hidden",
        tData: "hidden",
        ulList: "hidden",
        list: "hidden",
        title: "hidden",
        mainLink: "hidden",
    },
}
