import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"

const Button = props => {
    const {
        className,
        text,
        children: _children,
        dataActionDetail,
        onClick,
        disabled,
        id,
        gaEventClass = "",
        variant = "primary",
    } = props
    let children
    const finalClassName = clsx(
        variant === "primary" && "btn-primary",
        variant === "secondary" && "btn-secondary",
        gaEventClass,
        className
    )
    if (typeof _children === "object") {
        children = _children
    } else if (typeof _children === "string") {
        children = <span>{_children}</span>
    }

    return (
        <button
            className={finalClassName}
            data-action-detail={dataActionDetail}
            onClick={onClick}
            disabled={disabled}
            id={id}
        >
            <div className="overlay-wrapper">
                <div className="overlay">&nbsp;</div>
            </div>
            <div className="content">{children || <span>{text}</span>}</div>
        </button>
    )
}

Button.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
    children: PropTypes.any,
    dataActionDetail: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    gaEventClass: PropTypes.string,
    variant: PropTypes.oneOf(["primary", "secondary"]),
}

export default Button
