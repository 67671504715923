import React from "react"
import PropTypes from "prop-types"
import * as styles from "./ImageCard-tw-styles"
import ResponsiveImage from "../ResponsiveImage/ResponsiveImage"
import RichTextModel from "../../RichTextModel/RichTextModel"
import Link from "../Link/Link"
import CursorIcon from "../../../assets/icons/CursorIcon"

function ImageCard(props) {
    const { image, content, link, gridClasses, variant = "BUY_NOW" } = props
    const style = styles[variant]
    const CardContent = () => (
        <>
            {!!image && (
                <div className={style.imageWrapper}>
                    <div className={`${style.imageBgWrapper}`}>
                        <div className={`${style.imageBg}`}>&nbsp;</div>
                    </div>
                    <ResponsiveImage contentfulImagesComponent={image} className={style.image} />
                    {!!link && (
                        <div className={style.cursorIconWrapper}>
                            <CursorIcon />
                        </div>
                    )}
                </div>
            )}
            {!!content && <RichTextModel richText={content} style={style.content} />}
        </>
    )

    return (
        <>
            {link ? (
                <Link {...link} className={`${style.linkWrapper} ${gridClasses}`}>
                    <CardContent />
                </Link>
            ) : (
                <div className={`${style.wrapper} ${gridClasses}`}>
                    <CardContent />
                </div>
            )}
        </>
    )
}

export default ImageCard

ImageCard.propTypes = {
    image: PropTypes.object,
    content: PropTypes.object,
    link: PropTypes.object,
    gridClasses: PropTypes.string,
    variant: PropTypes.oneOf(["BUY_NOW", "CONTACT"]),
}
