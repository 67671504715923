export const defaultStyle = {
    wrapper: "relative",
    content: "section-wrapper",
}

export const WRAPPED = {
    wrapper: "relative",
    content: "section-wrapper",
}

export const COOKIES_SNIPPET = {
    wrapper: "relative",
    content: "section-wrapper max-w-[860px] pt-8 cookies-snippet",
}
export const COOKIES_BUTTON_SNIPPET = {
    wrapper: "relative",
    content: "section-wrapper max-w-[860px] pt-4 pb-16",
}
