import React from "react"
import PropTypes from "prop-types"
import parse from "html-react-parser"

const DangerousHTML = props => {
    const { style, content } = props

    return (
        <div className={style.wrapper}>
            <div className={style.content}>{parse(content)}</div>
        </div>
    )
}

DangerousHTML.propTypes = {
    style: PropTypes.object,
    content: PropTypes.string,
}

export default DangerousHTML
