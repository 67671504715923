export const defaultStyle = {
    footer: "relative w-full bg-black z-[7] text-white",
    wrapper: "section-wrapper flex flex-col",
    mobile: {
        wrapper: "section-wrapper flex flex-col items-center justify-start gap-[30px] pt-[60px] pb-[20px]",
        hr: "w-full border-white",
        footerImageWrapper: "flex flex-col items-center justify-center gap-[16px]",
        footerImageText: "heading-5 text-center uppercase",
        mainLinksContainer: "w-full grid grid-cols-2 md:grid-cols-3 gap-[30px]",
        mainLinkColumn: "flex flex-col items-start justify-start gap-[30px]",
        mainLinkHeading: "heading-5 text-white uppercase",
        mainLink: "body-l font-normal hover:underline",
        bottomContainer: "w-full flex flex-col gap-[30px] items-center justify-center",
        row: "w-full flex flex-col md:flex-row items-center justify-between gap-[30px]",
        brandLogoWrapper: "w-[72px] h-[72px]",
        languageSwitcherWrapper: "hidden",
        baseLinksWrapper: "w-full flex flex-row items-start justify-between gap-[4px] ",
        baseLink: "body-m font-normal hover:underline",
        copyright: "body-s font-normal text-center",
    },
    desktop: {
        wrapper: "section-wrapper flex flex-col gap-[24px] py-[24px]",
        hr: "w-full border-white",
        firstRow: "w-full flex flex-row justify-between gap-[30px]",
        footerImageWrapper: "flex flex-col items-center justify-center gap-[30px] min-w-[415px]",
        footerImageText: "heading-5 text-center uppercase",
        mainLinksContainer: "w-fit flex flex-row gap-[60px] align-self-end mt-[48px]",
        mainLinkColumn: "flex flex-col items-start justify-start gap-[16px] min-w-[136px]",
        mainLinkHeading: "heading-5 text-white uppercase",
        mainLink: "body-l font-normal hover:underline",
        lastRow: "w-full flex flex-row items-center justify-between",
        leftColumn: "flex flex-row items-center justify-between gap-[40px]",
        brandLogoWrapper: "w-[88px] h-[88px]",
        copyright: "body-m font-normal text-start",
        rightColumn: "flex flex-row items-center justify-between gap-[48px]",
        baseLinksWrapper: "w-fit flex flex-row items-start justify-between gap-[16px]",
        baseLink: "body-m font-normal hover:underline",
        languageSwitcherWrapper: "hidden",
    },
}
