const { fragment: LinkFragment } = require("../UI/Link/Link.fragment")
const fragment = `
    json
    links {
        entries {
            inline {
                sys {
                    id
                }
                ... on Link {
                    ${LinkFragment}
                }
            }
        }
    }
`
module.exports = { fragment }
