const { fragment: ResponsiveImageFragment } = require("../UI/ResponsiveImage/ResponsiveImage.fragment")
const { fragment: RichTextFragment } = require("../RichTextModel/RichText.fragment")
const { fragment: LinkFragment } = require("../UI/Link/Link.fragment")

const fragment = `
    showScrollPrompt
    image {
        ${ResponsiveImageFragment}
    }
    backgroundImage {
        ${ResponsiveImageFragment}
    }
    description{
        ${RichTextFragment}
    }
    cta {
        ${LinkFragment}
    }
    sectionId
    variant
    showImageOverlay
`
module.exports = { fragment }
