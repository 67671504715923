export const defaultStyle = {
    wrapper: "hidden",
    clipWrapper: "",
    decoration1Wrapper: "z-[10] pointer-events-none absolute",
    decoration1Image: "",
    decoration2Wrapper: "z-[10] pointer-events-none absolute",
    decoration2Image: "",
}

export const OUR_RANGE = {
    wrapper: "relative",
    clipWrapper: "bg-grey section-clip-top",
    decoration1Wrapper:
        "z-[10] pointer-events-none absolute top-[-20px] left-[-20px] md:top-[-40px] md:left-[-60px] lg:top-[-75px] lg:left-[0]",
    decoration1Image: "w-[145px] md:w-[170px] xxl:w-[265px]",
    decoration2Wrapper: "z-[10] pointer-events-none absolute top-[-20px] right-[-80px] xxl:right-0",
    decoration2Image: "w-[190px] md:w-[252px] lg:w-[350px]",
    contentWrapper: "relative section-wrapper pt-[60px] pb-[60px]",
    title: "heading-2 text-center uppercase mb-[24px]",
    gridWrapper: "grid grid-cols-12 gap-0 md:gap-[24px] w-fit mx-auto max-w-[280px] md:max-w-[640px] lg:max-w-[864px]",
}
export const BUY_NOW = {
    wrapper: "relative",
    clipWrapper: "bg-grey section-clip-bottom",
    decoration1Wrapper:
        "z-[10] pointer-events-none absolute left-[-50px] bottom-[-40px] md:left-[-120px] lg:left-[-160px] xxl:left-0",
    decoration1Image: "w-[165px] md:w-[269px] lg:w-[391px]",
    decoration2Wrapper:
        "z-[10] pointer-events-none absolute top-[-20px] right-[-80px] md:top-[-60px] lg:right-[-40px] lg:top-[-160px] xxl:right-0",
    decoration2Image: "w-[157px] md:w-[257px] lg:w-[374px]",
    contentWrapper: "relative section-wrapper pt-[60px] pb-[60px]",
    title: "heading-2 text-center uppercase mb-[24px]",
    gridWrapper: "grid grid-cols-12 gap-[24px] w-fit mx-auto",
}

export const CONTACT_SECTION = {
    wrapper: "relative ",
    clipWrapper: "section-clip-top section-clip-bottom contact-section-bg",
    decoration1Wrapper:
        "z-[10] pointer-events-none absolute top-[60%] left-[-110px] md:top-[45%] md:left-[-90px] lg:left-[0] lg:top-[50%]",
    decoration1Image: "w-[180px] md:w-[263px]",
    decoration2Wrapper: "z-[10] pointer-events-none absolute top-[55%] right-[-50px] md:top-[35%] lg:right-[0]",
    decoration2Image: "w-[124px] md:w-[183px] lg:w-[211px]",
    contentWrapper: "relative section-wrapper pt-[60px] pb-[60px]",
    title: "heading-2 text-center uppercase mb-[24px]",
    gridWrapper: "grid grid-cols-12 gap-[24px] lg:gap-y-[60px] w-fit mx-auto",
}
