import React from "react"

function PlayIcon() {
    return (
        <svg width="78" height="79" viewBox="0 0 78 79" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39 71.5254C56.9493 71.5254 71.5 56.9746 71.5 39.0254C71.5 21.0761 56.9493 6.52539 39 6.52539C21.0507 6.52539 6.5 21.0761 6.5 39.0254C6.5 56.9746 21.0507 71.5254 39 71.5254Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M57.418 39.0254L28.9805 56.0879L28.9805 21.9629L57.418 39.0254Z"
                fill="white"
            />
        </svg>
    )
}

export default PlayIcon
