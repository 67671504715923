/** @type {import('tailwindcss').Config} */

const colors = {
    white: "#FFFFFF",
    black: "#000000",
    grey: "#F6F5F5",
    "product-mint": "#8DCCBB",
    "product-beige": "#DFD2C6",
    "product-grey": "#EEEAE7",
    "product-pink": "#F4AACB",
}

module.exports = {
    content: [
        "./src/pages/**/*.{js,jsx,ts,tsx}",
        "./src/templates/**/*.{js,jsx,ts,tsx}",
        "./src/components/**/*.{js,jsx,ts,tsx}",
        "./src/assets/**/*.{js,jsx,ts,tsx}",
    ],
    safelist: [
        {
            pattern: /col-span-/,
            variants: ["sm", "md", "lg", "xxl"],
        },
        {
            pattern: /col-start-/,
            variants: ["sm", "md", "lg", "xxl"],
        },
        {
            pattern: /(flex|flex-col|flex-col-reverse|flex-row|flex-row-reverse)/,
            variants: ["sm", "md", "lg", "xxl"],
        },
        {
            pattern: /bg-product-.*/,
            variants: ["sm", "md", "lg", "xxl"],
        },
    ],
    theme: {
        screens: {
            xxl: "1920px",
            lg: "1440px",
            md: "768px",
            sm: "390px",
        },
        fontFamily: {
            aptos: "Aptos",
        },
        extend: {
            colors: {
                ...colors,
            },
            stroke: {
                ...colors,
            },
            fill: {
                ...colors,
            },
            placeholderColor: {
                ...colors,
            },
            flexGrow: {},
            spacing: {},
            fontSize: {},
            lineHeight: {},
            borderWidth: {},
            borderRadius: {},
            inset: {
                auto: "auto",
            },
            height: {},
            minHeight: {
                auto: "auto",
            },
            maxWidth: {},
            minWidth: {},
            maxHeight: {},
            opacity: {},
            zIndex: {},
            gap: {},
            backgroundImage: {},
            translate: {},
            transitionDuration: {},
            rotate: {},
            boxShadow: {},
            fontFamily: {},
        },
    },
    variants: {
        extend: {
            stroke: ["hover"],
        },
    },
    plugins: [
        function ({ addVariant }) {
            addVariant("child", "& > *")
            addVariant("child-hover", "& > *:hover")
        },
    ],
}
