const { fragment: RichTextFragment } = require("../RichTextModel/RichText.fragment")
const { fragment: LinkFragment } = require("../UI/Link/Link.fragment")
const { fragment: ResponsiveImageFragment } = require("../UI/ResponsiveImage/ResponsiveImage.fragment")

const query = (id, isPreview) => `
{
  richTextModel(id: "${id}", preview: ${isPreview}) {
    internalTitle
    variant
    title
    richText{
        ${RichTextFragment}
    }
    link {
        ${LinkFragment}
    }
    domId
    decoration1 {
        ${ResponsiveImageFragment}
    }
    decoration2 {
        ${ResponsiveImageFragment}
    }
  }
}
`
module.exports = { query }
