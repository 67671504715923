export const BUY_NOW = {
    linkWrapper: "relative flex flex-col gap-0 group",
    wrapper: "relative flex flex-col gap-0",
    imageWrapper:
        "relative w-full flex items-center justify-center min-h-[157px] min-w-[280px] md:min-w-[242px] lg:min-w-[350px]",
    imageBgWrapper: "absolute inset-0 transition-all duration-300 ease-in-out group-hover:image-card-filter",
    imageBg: "absolute inset-0 bg-white image-card-clip",
    image: "relative",
    cursorIconWrapper: "absolute right-[16px] bottom-[16px]",
    content: {
        richTextWrapper: "hidden",
        code: "hidden",
        link: "hidden",
        paragraph: "hidden",
        heading1: "hidden",
        heading2: "hidden",
        heading3: "hidden",
        heading4: "hidden",
        heading5: "hidden",
        heading6: "hidden",
        table: "hidden",
        tRow: "hidden",
        tHeader: "hidden",
        tData: "hidden",
        ulList: "hidden",
        list: "hidden",
        title: "hidden",
        mainLink: "hidden",
    },
}

export const CONTACT = {
    linkWrapper: "relative flex flex-col gap-[24px] items-center w-fit justify-self-center group",
    wrapper: "relative flex flex-col gap-[24px] items-center w-fit justify-self-center",
    imageWrapper:
        "relative w-full flex items-center justify-center min-h-[157px] min-w-[280px] md:min-w-[220px] lg:min-w-[280px]",
    imageBgWrapper:
        "absolute inset-0 transition-all duration-300 ease-in-out contact-card-filter group-hover:image-card-filter",
    imageBg: "absolute inset-0 bg-white image-card-clip",
    image: "relative w-[72px] h-[72px] lg:w-[96px] lg:h-[96px]",
    cursorIconWrapper: "hidden",
    content: {
        richTextWrapper: "text-center flex flex-col gap-[8px]",
        code: "hidden",
        link: "body-m font-normal hover:underline",
        paragraph: "body-m font-normal [&_b]:font-black",
        heading1: "hidden",
        heading2: "hidden",
        heading3: "hidden",
        heading4: "heading-4 uppercase",
        heading5: "hidden",
        heading6: "hidden",
        table: "hidden",
        tRow: "hidden",
        tHeader: "hidden",
        tData: "hidden",
        ulList: "hidden",
        list: "hidden",
        title: "hidden",
        mainLink: "hidden",
    },
}
