import { SORT_A_TO_Z, SORT_DEFAULT, SORT_NEWEST, SORT_TOP_RATED, SORT_Z_TO_A } from "../constants/rules.constants"

export const sortOptions = {
    [SORT_DEFAULT]: {
        label: SORT_DEFAULT,
    },
    [SORT_TOP_RATED]: {
        label: SORT_TOP_RATED,
        order: "asc",
        key: "date",
    },
    [SORT_NEWEST]: {
        label: SORT_NEWEST,
        order: "desc",
        key: "date",
    },
    [SORT_A_TO_Z]: {
        label: SORT_A_TO_Z,
        order: "asc",
        key: "title",
    },
    [SORT_Z_TO_A]: {
        label: SORT_Z_TO_A,
        order: "desc",
        key: "title",
    },
}
