import React from "react"
import { SVG_NAME_SPACE } from "../../constants/label.constants"

const InstagramIcon = (className, name) => (
    <svg className={className} data-action-detail={name} xmlns={SVG_NAME_SPACE} viewBox="0 0 152.8 152.5">
        <path
            className="st0"
            fill="#fff"
            d="M117.3 26.4c-5 0-9.2 4-9.2 9.1-.1 5 4 9.2 9.1 9.2 5.4 0 9.2-4.3 9.2-9.1.1-5-4-9.2-9.1-9.2zM76.4 37c-21.4.1-39.2 17.6-39.1 39.2-.1 21.6 17.6 39.2 39.1 39.3 21.6 0 39.3-17.5 39.3-39.2.1-21.8-17.7-39.4-39.3-39.3zm0 64.7C62.7 101.7 51 90.5 51 76.2c.1-14.1 11.4-25.4 25.5-25.4 14.3-.1 25.5 11.6 25.5 25.4 0 14-11.5 25.5-25.6 25.5z"
        ></path>
        <path
            className="st0"
            fill="#fff"
            d="M152.7 61.2c0-6.1 0-12.1-.4-18.2-.3-4.9-1-9.7-2.6-14.4-4-12-11.9-20.3-23.7-24.9-5.6-2.2-11.6-3-17.6-3.3-4.3-.2-8.5-.4-12.8-.4C82.3 0 69 0 55.7.1 50.5.1 45.2.3 40 .8c-4.5.5-9 1.5-13.3 3.1-8.7 3.3-15.3 9-20 17-3.3 5.7-5 11.9-5.7 18.4-.4 3.6-.6 7.2-.7 10.8L0 76.2h.1v5.4c.1 9.7 0 19.4.7 29.1.4 5.3 1.3 10.5 3.3 15.5 4 10.1 10.9 17.4 20.8 21.8 5.4 2.4 11.2 3.6 17.2 4 5.1.3 10.2.5 15.3.5 13.6.1 27.1 0 40.7 0 4.8-.1 9.7-.2 14.5-.7 4.7-.4 9.2-1.3 13.6-3 9.8-3.8 17-10.4 21.6-19.9 2.8-5.7 4.1-11.9 4.5-18.2.3-5.4.5-10.9.5-16.4 0-11.1 0-22.2-.1-33.1zM139 93.9c0 4.7-.2 9.4-.4 14.1-.2 4.1-.8 8.1-2.1 12-2.8 8.1-8.3 13.6-16.4 16.4-3.2 1.1-6.4 1.6-9.7 1.9-6.9.6-13.8.7-20.7.7-10.5 0-21 0-31.5-.1-4.5 0-8.9-.2-13.4-.4s-9-.8-13.3-2.5c-7.5-2.9-12.6-8.2-15.2-15.9-1.3-3.9-1.9-7.9-2.1-11.9-.5-10.6-.4-21.3-.4-31.9 0-6.4-.1-12.7 0-19.1.1-4.5.2-8.9.5-13.3.2-3.8.8-7.5 2-11.1 2.8-8.5 8.6-14.1 17.1-16.8 3.6-1.2 7.3-1.7 11.1-1.9 17.2-.8 34.5-.4 51.8-.4 4.2 0 8.4.2 12.5.4 4.1.3 8.1.9 12 2.3 7.9 2.8 13.1 8.2 15.8 16.1 1.1 3.2 1.7 6.4 1.9 9.7.6 7.2.7 14.5.5 21.8.1 9.9.1 19.9 0 29.9z"
        ></path>
    </svg>
)

export default InstagramIcon
