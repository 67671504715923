import React from "react"
import PropTypes from "prop-types"
import ResponsiveImage from "../UI/ResponsiveImage/ResponsiveImage"
import Typography from "../UI/Typography/Typography"

const ImageWithText = ({contentImage, image, text, style}) => {

    return <div className={style.wrapper}>
        <div className={style.container}>
            <div className={style.content}>
                <ResponsiveImage contentfulImagesComponent={contentImage} className={style.contentImage} />
                <Typography content={text} className={style.text} />
            </div>
            <ResponsiveImage contentfulImagesComponent={image} className={style.image} />
        </div>
    </div>
}

ImageWithText.propTypes = {
    image: PropTypes.object,
    contentImage: PropTypes.object,
    text: PropTypes.string,
    style: PropTypes.object
}

export default ImageWithText
