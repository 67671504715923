import React from "react"
import PropTypes from "prop-types"
import ContentfulComponents from "../helpers/ContentfulComponents"
import Layout from "../components/Layout/Layout"
import GlobalContext from "../context/GlobalContext"
import { setDataLayer } from "../helpers/dataLayerSetter"

const Page = ({ pageContext }) => {
    const { globalComponents, componentProps, seoData, pageType, dataLayer, globalLabels, pageLanguage } = pageContext
    setDataLayer(dataLayer, seoData, pageType)

    return (
        <GlobalContext labels={globalLabels}>
            <Layout seoData={seoData} globalComponents={globalComponents} pageLanguage={pageLanguage}>
                <ContentfulComponents componentsProps={componentProps} pageType={pageType} />
            </Layout>
        </GlobalContext>
    )
}
export const Head = () => {
    const dataObject = typeof window !== "undefined" ? window?.gtmDataLayer : {}
    return (
        <>
            <script
                id="first-unique-id"
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    __html: `var MdlzdataLayer = ${JSON.stringify(dataObject)};`,
                }}
            />
        </>
    )
}

Page.propTypes = {
    pageContext: PropTypes.shape({
        globalComponents: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        componentProps: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        pageType: PropTypes.string,
        dataLayer: PropTypes.object,
        seoData: PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            ogTitle: PropTypes.string,
            ogDescription: PropTypes.string,
            ogType: PropTypes.string,
            twitterTitle: PropTypes.string,
            twitterDescription: PropTypes.string,
            canonicalLink: PropTypes.string,
            metaTitle: PropTypes.string,
            featuredImage: PropTypes.shape({
                url: PropTypes.string,
            }),
        }),
        globalLabels: PropTypes.object,
        pageLanguage: PropTypes.string,
    }),
}

export default Page
