import React from "react"
import PropTypes from "prop-types"
import SeoHead from "../../Scripts/SeoHead"
import GtmBody from "../../Scripts/Gtm.Body"

const ScriptWrapper = props => {
    const { seoHead } = props

    return (
        <>
            <SeoHead {...seoHead} />
            <GtmBody />
        </>
    )
}

ScriptWrapper.propTypes = {
    seoHead: PropTypes.object,
}

export default ScriptWrapper
