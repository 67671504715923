const { fragment: ResponsiveImageFragment } = require("../ResponsiveImage/ResponsiveImage.fragment")
const { fragment: RichTextFragment } = require("../../RichTextModel/RichText.fragment")
const fragment = `
    sys {
        id
    }
    image {
        ${ResponsiveImageFragment}
    }
    content {
        ${RichTextFragment}
    }
    color
`
module.exports = { fragment }
