const { fragment: ResponsiveImageFragment } = require("../UI/ResponsiveImage/ResponsiveImage.fragment")

const query = (id, isPreview) => `
{
    imageWithText(id: "${id}", preview: ${isPreview}) {
        image {
            ${ResponsiveImageFragment}
        }
        contentImage {
            ${ResponsiveImageFragment}
        }
        text
        variant
    }
}
`
module.exports = { query }
