import React from "react"
import PropTypes from "prop-types"
import ResponsiveImage from "../UI/ResponsiveImage/ResponsiveImage"
import BannerCard from "../UI/BannerCard/BannerCard"
import RangeCard from "../UI/RangeCard/RangeCard"
import ImageCard from "../UI/ImageCard/ImageCard"
import VideoCard from "../UI/VideoCard/VideoCard"

const GridSection = props => {
    const { style, title, itemsCollection, sectionId, decoration1, decoration2 } = props
    return (
        <div className={style?.wrapper} id={sectionId}>
            {!!decoration1 && (
                <div className={style?.decoration1Wrapper}>
                    <ResponsiveImage contentfulImagesComponent={decoration1} className={style?.decoration1Image} />
                </div>
            )}
            {!!decoration2 && (
                <div className={style?.decoration2Wrapper}>
                    <ResponsiveImage contentfulImagesComponent={decoration2} className={style?.decoration2Image} />
                </div>
            )}
            <div className={style?.clipWrapper}>
                <div className={style?.contentWrapper}>
                    {!!title && <h2 className={style?.title}>{title}</h2>}
                    <div className={style?.gridWrapper}>
                        {itemsCollection.items.map((item, index) => (
                            <React.Fragment key={`${item?.content?.sys?.id}-${index}`}>
                                {item?.content?.__typename === "BannerCard" && (
                                    <BannerCard {...item.content} gridClasses={item.gridClasses} />
                                )}
                                {item?.content?.__typename === "RangeCard" && (
                                    <RangeCard {...item.content} gridClasses={item.gridClasses} />
                                )}
                                {item?.content?.__typename === "ImageCard" && (
                                    <ImageCard {...item.content} gridClasses={item.gridClasses} />
                                )}
                                {item?.content?.__typename === "VideoCard" && (
                                    <VideoCard {...item.content} gridClasses={item.gridClasses} />
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

GridSection.propTypes = {
    style: PropTypes.object,
    title: PropTypes.string,
    itemsCollection: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.object),
    }),
    sectionId: PropTypes.string,
    decoration1: PropTypes.object,
    decoration2: PropTypes.object,
}

export default GridSection
