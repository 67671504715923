export const labels = {
    back: "Back to all recipes",
    watch: "Watch video",
    making: "The makings of the",
    socialIconVar: "socialIconVar",
    philadelphia: "Philadelphia",
    recepieDetail: "recepieDetail",
    recipeDeveloped: "A recipe developed exclusively for Philadelphia Professional by Jessica Ayling",
    instagram: "instagram",
    whatsapp: "whatsapp",
    instagramHref: "https://www.instagram.com/philadelphiaprofessional_ukie/",
    productLink: "/range",
    bookmark: "bookmark",
    servings: "Servings",
    min: "min",
    totalTime: "Total time",
    prepTime: "Prep time",
    mail: "mail",
    print: "print",
    servings100: "Per serving 100g",
    servings30: "Per serving 30g",
    imageTextBox: "IMAGE_TEXT_BOX",
    imageLayout: "Image_Layout",
    imageCardsLayout: "Image_Cards_Layout",
    imageTextBoxLayout: "IMAGE_TEXT_BOX_LAYOUT",
    mailSubject: "I found a recipe you may like",
    mailBody: "I found this delicious recipe made with Philadelphia. You can check it out here:",
    happyCooking: "There are many more recipes to discover,too. Happy cooking!",
    tabletitle: "Nutritional <strong>Information</strong>",
    metaDesRecipe: "Philadelphia Professional UK - Recipe",
    metaTitle: "Philadelphia Professional UK | Recipe ",
    beadCrumb: {
        homeBread: "Home",
    },
    videoNotSupported: "Your browser does not support the video tag.",

    recipiesForm: {
        firstNameLabel: "First name",
        lastNameLabel: "Last name",
        jobTitleLabel: "Job Title",
        establishmentNameLabel: "Establishment Name",
        workSectorLabel: "Sector you work in",
        wholeSalerLabel: "Preferred wholesaler",
        emailLabel: "Email",
        title: "Free Education Recipe Book",
        description:
            "Fill in your details below to download Nicole's full collection of recipes, including exclusive hints and tips, along with nutritionals and costings for each recipe",
        submitLabel: "Submit",
        submitError: "Some information seems incomplete or incorrect. To make corrections please to continue.",
        confirmationCheckboxLabel: "I confirm that I am over 18 years old",
        aggerementCheckboxLabel:
            "If you would like to receive future news, recipes, information on trends and new Philadelphia products by email or paper, please tick this box. If you no longer wish to receive emails or paper, you may unsubscribe here. We value your trust when sharing your personal data with us. We always treat your data in a fair and respectful manner limited to the purpose above mentioned. If you would like to know more about how we handle your data, please read our Privacy Notice.",
    },

    ingredients: {
        title: "Ingredients",
        iamDoneBtn: "I'm done",
        editScaleBtn: "Edit Scales and Units",
        servings: "servings",
        g: "g",
        lbs: "lbs",
    },
    modelheading: "Bookmark shortcuts",
    modelDes1: "  To add this recipe to your bookmarks, please use one of the following shortcuts.",
    modelDes2: "  Windows (Internet Explorer, Firefox, etc.)",
    modelDes3: "CTRL+D",
    modelDes4: " MacOS (Safari)",
    modelDes5: "Command(cmd)+D",
    leftArrow: "leftArrow",
    rightArrow: "rightArrow",
    iconNotFound: "Icon not found",
    search: "Search",
    youtubePlayer: "YouTube video player",
    sharetxt: "Spread the word, chef! ",
    ingredientsText: "Ingredients",
    productKeys: {
        prdName: "prd_name",
        prdHeading: "philadelphia_product_name",
        prdDesc: "philadelphia_mood",
        prdIngredients: "philadelphia_product_ingredients",
    },
    category: {
        imgs: "imgs",
        texts: "texts",
        nutritions: "nutritions",
    },
    tableHeading: {
        heading: "Nutrional <strong>Information</strong>",
        typicalValues: "Typical values",
        serving: "Nutrition Per 100g",
        gda: "GDA",
        percent: "%",
    },
    gdaValues: {
        Energy: "2000",
        Protein: "50g",
        Carbohydrate: "260g",
        "of which Sugars": "90g",
        Fat: "70g",
        "of which Saturates": "20g",
        Fibre: "24g",
        "Sodium*": "6g",
    },
    energy: "Energy",
    kcal: "kcal",
    kJ: "kJ",
    enjoyCreating: "Enjoy creating",
    nutritionTitle: "Nutritional Information",
    typicalValues: "Typical values",
    perServing: "Per Serving",
    sat_fat: "Sat_fat",
    sodium: "Sodium",
    dit_fibre: "Dietary_fibre",
    saturated_fat: "Saturated fat",
    salt: "Salt",
    dietary_fibre: "Dietary fibre",
}

export const SORT_TOP_RATED = "Top Rated"
export const SORT_DEFAULT = "Default"
export const SORT_NEWEST = "Newest"
export const SORT_A_TO_Z = "A-Z"
export const SORT_Z_TO_A = "Z-A"
export const TOGGLE_PASSWORD = "togglePassword"
export const PASSWORD = "password"
export const SVG_NAME_SPACE = "https://www.w3.org/2000/svg"
export const JOB_TITLE = "jobTitle"
export const WORK_SECTOR = "workSector"
export const JOB_TITLE_INPUT = "jobTitleInput"
export const WORK_SECTOR_INPUT = "workSectorInput"
export const MANDATORY = "*Mandatory fields"
export const INGREDIENT_PHOTO_ID = 2106
export const BANNER_PHOTO_ID = 2051
export const NUTRITION_PHOTO_ID = 2054
export const PRODUCTIDs = ["95", "96"]
export const PRODUCTIDOLGH = 96
export const APPID = "05def51a-7539-11ed-a1eb-0242ac120002"
export const APP_SITE_KEY = "6LeLkF8jAAAAABEC1_wVI9cHPdTUOgLv8cH2VtoJ"
export const API_ERROR_MSG = "Something Went Wrong! Please try again"
