const { fragment: ResponsiveImageFragment } = require("../ResponsiveImage/ResponsiveImage.fragment")
const { fragment: RichTextFragment } = require("../../RichTextModel/RichText.fragment")
const fragment = `
    sys {
        id
    }
    thumbnailImage {
        ${ResponsiveImageFragment}
    }
    content {
        ${RichTextFragment}
    }
    youtubeVideoUrl
`
module.exports = { fragment }
