export const defaultStyle = {
    header: "fixed z-[15] left-0 right-0 header-shadow bg-white",
    sectionWrapper: "section-wrapper w-full",
    wrapper: "relative flex flex-wrap justify-center md:justify-between items-center h-[72px] md:h-[96px]",
    logoWrapper: "z-[20] w-[125px] h-[70px] md:w-[172px] md:h-[96px] translate-y-[22%]",
    desktopLinksWrapper: "hidden md:flex flex-row items-end gap-2",
    desktopLink: "text-black body-l font-normal hover:underline",
    burgerButton:
        "absolute md:hidden right-0 w-[24px] h-[24px] flex flex-col gap-[4.5px] items-center justify-center text-center cursor-pointer",
    burger1Line:
        "w-[17.5px] h-[1.5px] block absolute bg-black transition-all duration-300 ease-in-out origin-center top-[5px]",
    burger2Line: "w-[17.5px] h-[1.5px] block absolute bg-black transition-all duration-300 ease-in-out origin-center",
    burger3Line:
        "w-[17.5px] h-[1.5px] block absolute bg-black transition-all duration-300 ease-in-out origin-center top-[17px]",
    burgerLine1Active: "rotate-45 origin-center !top-1/2",
    burgerLine2Active: "scale-0",
    burgerLine3Active: "-rotate-45  origin-center !top-1/2",
    mobileMenuWrapper: "fixed z-[10] top-[72px] md:top-[96px] right-0 bottom-0 left-0 bg-white",
    mobileMenu: "px-[20px] pt-[50px] pb-[20px] flex flex-col items-between h-full",
    mobileMenuLinksWrapper: "h-full w-full flex flex-col items-between",
    mobileMenuLink: "text-black heading-5 py-[24px] uppercase w-full",
    mobileMenuHr: "w-full border-b border-product-beige",
}
