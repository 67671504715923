const { fragment: ResponsiveImageFragment } = require("../../UI/ResponsiveImage/ResponsiveImage.fragment")
const { fragment: FooterLinksContainerFragment } = require("./FooterLinksContainer.fragment")

const query = (isPreview, locale) => `{
  footerCollection(preview: ${isPreview}, limit: 1, locale: "${locale}") {
    items {
      internalTitle
      footerImage {
        ${ResponsiveImageFragment}
      }
      footerImageText
      mainLinksCollection {
        items {
            ... on FooterLinksContainer {
                ${FooterLinksContainerFragment}
            }
        }
      }
      baseLinks {
        ${FooterLinksContainerFragment}
      }
      brandLogoImage {
        ${ResponsiveImageFragment}
      }
      copyright
    }
  }
}
`
module.exports = { query }
